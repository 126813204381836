<template>
<v-row>
    <v-col cols="12" md="4" :class="$vuetify.breakpoint.smAndDown?'':'tv-bl'"  class="pt-0" >
        <h3>Gebiete</h3>
                <v-treeview
                    v-if="load"
                    dense
                    hoverable
                    open-on-click
                    activatable
                    :open.sync="open"
                    :active.sync="active"
                    :items="items"
                    ></v-treeview>
    </v-col>

    <v-col cols="12" md="8" class="pt-0">
        <v-divider v-if="$vuetify.breakpoint.smAndDown" class="mb-5" id="divider"></v-divider>

        <template v-if="ready">
            <v-card tile class="mb-5" >
                <v-img :src="'https://directus.stonevibes.de/assets/'+pageData.titelbild"></v-img>
            </v-card>
            <h2    class="mb-5">{{pageData.subtitel}}</h2>
            <div   class="" v-html="pageData.text"></div>
        </template>

        <template v-if="topoPath">
            <v-card tile class="mb-5" >
                <v-img :src="'https://directus.stonevibes.de/assets/'+topoPath"></v-img>
            </v-card>
        </template>

        <v-btn
                v-if="$vuetify.breakpoint.smAndDown"
                bottom
                light
                fixed
                right
                fab
                @click="$vuetify.goTo('.v-treeview-node--active',{offset:160})"
                ><v-icon>mdi-chevron-up</v-icon></v-btn>
        <v-expansion-panels multiple v-model="boulderPanel" >
          <v-expansion-panel v-for="(item,i) in boulderItems" :key="i" @click="onExpansionPanelClick(i)" :id="'b-'+i">
            <v-expansion-panel-header>
              <h3>{{item.title}}</h3> {{item.grad.grade}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <h4>Gebiet</h4>
                {{path}}
                <template v-if="item.description">
                    <h4 class="mt-2">Allgemeine Beschreibung / Definitionen</h4>
                    {{item.description}}
                </template>

                <template v-if="item.height">
                    <h4 class="mt-2">Höhe</h4>
                    {{item.height}}
                </template>

                <template v-if="item.variant">
                    <h4 class="mt-2">Variante (z.B. Sitzstart, Traverse)</h4>
                    {{item.variant}}
                </template>

                <template v-if="item.bolts">
                    <h4 class="mt-2">Haken</h4>
                    {{item.bolts}}
                </template>

                <template v-if="item.owner || item.ownerdate">
                    <h4 class="mt-2">
                        <span v-if="item.owner">Erstbegeher</span>
                        <span v-if="item.owner && item.ownerdate"> / </span>
                        <span v-if="item.ownerdate">Datum</span>
                    </h4>
                    {{item.owner}}<span v-if="item.owner && item.ownerdate"> / </span>{{item.ownerdate}}
                </template>
                <v-divider class="my-3"></v-divider>
                <v-container class="px-0">
                <v-row class="flex-nowrap no-gutters" :class="$vuetify.breakpoint.smAndDown?'text-body-3':'text-body-2'" style="gap:2px">
                    <v-col>Zugang</v-col>
                    <v-col>Exposition</v-col>
                    <v-col>Felsart</v-col>
                    <v-col>Qualität</v-col>
                    <v-col>Sicherheit</v-col>
                    <v-col>Kletterei</v-col>
                </v-row>
                <v-row class="flex-nowrap no-gutters" style="gap:2px">
                    <v-col><img class="icon-style" :src="getIcon(item,'access')" /></v-col>
                    <v-col><img class="icon-style" :src="getIcon(item,'exposition')" /></v-col>
                    <v-col><img class="icon-style" :src="getIcon(item,'rockstyle')" /></v-col>
                    <v-col><img class="icon-style" :src="getIcon(item,'quality')" /></v-col>
                    <v-col><img class="icon-style" :src="getIcon(item,'safety')" /></v-col>
                    <v-col><img class="icon-style" :src="getIcon(item,'climbingstyle')" /></v-col>
                </v-row>
                </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
    </v-col>
</v-row>
</template>

<script>
import {
    loadDdbItems,loadGebiete,loadItems,loadPageData
} from '@/api/api'

export default {
    name: 'Boulder',
    data: () => ({
        load: false,
        items: [],
        active: [],
        open:[],
        gebiete:[],
        path:"",
        pathArr:[],
        boulderItems:{},
        gebietePanel:0,
        boulderPanel:[0],
        metaPath:"",
        ready:false,
        pageData:{},
        topoPath:null
    }),
    methods: {
        fetchData() {
            this.metaPath = this.$route.meta.type;

            let typ = "";
            if (this.$route.meta.type == "routen") {
                typ = "rdb";
            }else{
                typ = "bdb";
            }

            loadGebiete(typ).then(data => {
                this.items = data.tree;
                this.gebiete = data.data;

                if (this.$route.params.hasOwnProperty("id") && +this.$route.params.id != 0) {
                    this.active = [+this.$route.params.id];
                    this.traverseUp(+this.$route.params.id);
                }
                let curGebiet = this.gebiete.find(gebiet => gebiet.id === +this.$route.params.id);
                this.topoPath = curGebiet?.titelbild;

                this.path = this.pathArr.join(" > ");

                this.pathArr = [];
                this.load = true;
            })

            if (this.$route.params.hasOwnProperty("id") && +this.$route.params.id != 0) {
                this.ready = false;

                loadItems(+this.$route.params.id, typ).then(data => {
                    this.boulderItems = data;
                    this.boulderPanel = [0]
                    if (this.$vuetify.breakpoint.smAndDown) {
                        this.$vuetify.goTo("#divider");
                    }
                })
            }else{
                let cId = (typ == "rdb")?7:13;

                loadPageData(cId).then(data => {
                    this.pageData = data;
                    this.ready = true;
                })

                this.boulderItems = {};
                this.active = []
            }
        },

        traverseUp(id){
            this.open.push(id)
            const result = this.gebiete.find(gebiet => gebiet.id === id);

            if (result.parent_id != null && result.parent_id != 1) {
                this.pathArr.unshift(result.title);
                this.traverseUp(result.parent_id);
            }else{
                this.pathArr.unshift(result.title);
            }
        },
        getIcon(item,icon){
            if (item["i_"+icon] == 0) {
                return "/assets/icon/icon.gif";
            }else{
                return '/assets/icon/'+icon+'_'+(item["i_"+icon] - 1)+'.gif';
            }
        },
        onExpansionPanelClick(i) {
            this.$vuetify.goTo("#b-"+i, {offset:10});
        }
    },

    computed: {
        selected () {
            if (this.$route.meta.type != this.metaPath) {
                this.active = []
                this.pathArr = [];
                this.open =[]
            }
            if (this.active.length > 0) {
                if (this.$route.params.id != this.active[0]) {
                    const result = this.gebiete.find(gebiet => gebiet.id == this.active[0]);
                    this.topoPath = result?.titelbild;

                    this.$router.push({path:`/${this.$route.meta.type}/${result.title}/${result.id}`})
                }
            }
        }
    },
    created() {
        this.fetchData();
    },
    watch: {
        $route: 'fetchData',
        selected:"selected",
    },
}
</script>

<style>
    .tv-bl{
        border-right: 1px solid rgba(0, 0, 0, 0.12);
    }

    .icon-style{
        max-width:63px;
        width:100%;
    }
</style>
